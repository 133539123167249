@import 'assets/variables';

header,
footer {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #333;
}
.required {
    color: $error-color !important;
}

.errorMessage {
    margin-top: 10px;
    margin-bottom: 15px;
}
