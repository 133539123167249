@import 'assets/variables';

h1,
h2,
h3,
h4,
h5 {
    color: $title-color !important;
}

.app {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    border-radius: 6px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 40px;
}

.logo {
    height: 120px;
    pointer-events: none;
}

.fixed-nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 50px;
    background-color: #00a087;
}

.app-header,
.app-footer {
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.app-header {
    background-color: black;
    color: $logo-color;
    height: 150px;
    top: 0;
}

.app-footer {
    background-color: #222;
    position: fixed;
    bottom: 0;
    color: gray;
    line-height: 40px;
    height: 40px;
}

.page-title {
    text-decoration: none;
    color: $title-color;
    font-size: 50px !important;
}

.subtitle {
    color: $subtitle-color !important;
}

.link {
    color: #61dafb;
}

// Semantic ui fixes
.ui.ribbon.label {
    border-color: #777777 !important;
}
.ui.menu .active.item {
    background-color: $orange !important;
    border-color: $orange !important;
}

.ui.pointing.menu .active.item::after {
    background-color: $orange !important;
    border-color: $orange !important;
}

.ui.menu {
    border-color: $orange !important;
}

.ui.header {
    color: $title-color !important;
}

.spacer {
    height: 20px;
}

.message_header {
    font-size: 20px !important;
}

.message_content {
    padding-top: 10px;
    font-size: 18px !important;
}

i.icon {
    color: #555 !important;
}

.task {

}

.task_done {
    text-decoration: line-through;
}

.newTaskBtn {
    margin-top: 20px;
}