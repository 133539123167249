//$highlight-color: #532B72
$background-color: #000000;
$title-color-inverse: #e6dff9;

$orange: #ef6422;
$secondary: #9151da;
$darkgrey: black;

$logo-color: $orange;
$title-color: $orange;
$subtitle-color: $secondary;
$content-background: $darkgrey;

// $background-light-color: #518fd4;
// $background-light-color-hover: #619fe4;
// $background-dark-color: #093b64 !important;
// $background-dark-color-hover: #194b74;
// $background-form-blue: #e2edff;
// $background-form-blue-hover: #eff3ff;
// $background-form-blue-selected: #bfd6fc;
// $background-submenu-color: #597EAA;
// $background-form: #efefef;

$error-color: #bd4a33;

$normal-font-family: 'Roboto', 'Arial';
$title-font-family: 'Roboto', 'Verdana';
