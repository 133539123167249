@import 'assets/variables';

.table {
    color: black;
    display: inline-block;
}

.table-part {
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: inline-block;
    border-radius: 2px;
}

.table-chair {
    height: 7px;
    width: 45px;
    background-color: darkcyan;
    border: 1px solid black;
    margin-bottom: -4px;
    margin-left: 20px;
    border-radius: 2px;
}

.table-normal {
    width: 85px;
    height: 70px;
    line-height: 70px;
}

.table-upside-down {
    transform: rotate(180deg);
}

.table-group-label {
    color: $secondary;
    position: absolute;
    left: 165px;
    top: 72px;
    z-index: 10;
    background-color: aliceblue;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 13px;
    padding-right: 13px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 3px;
}

.table-group-content {
    position: relative;
}

.table-group {
    display: inline-block;
    padding-top: 80px;
}

.table-group-normal {
    width: 350px;
    height: 190px;
}

.table-group-extra {
    width: 630px;
    height: 190px;
}

.table-food-table {
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: cadetblue;
    border-radius: 2px;
    width: 400px;
    height: 130px;
    line-height: 130px;
    margin-top: -680px;
    transform: rotate(90deg);
    float: right;
    padding-left: 120px;
    font-size: 18px;
    border-radius: 5px;
}

.table-button {
    border: none;
    padding: 0px;
    border-radius: 2px;
}

.table-free {
    background-color: olivedrab;
    border-radius: 2px;
}

.table-booked {
    background-color: #8d1717;
    border-radius: 2px;
}

.table-disabled {
    background-color: #cd672e;
    border-radius: 2px;
}

.table-disabled:hover {
    background-color: #cd672e !important;
}

.table-booked:hover {
    background-color: #8d1717 !important;
}

.table-button:hover {
    background-color: darkolivegreen;
    // color: white !important;
}

.table-booking {
    background-color: #4682b4;
    color: whitesmoke;
    font-weight: bold;
}

.table-booking:hover {
    background-color: #4682b4be !important;
    color: whitesmoke;
    font-weight: bold;
}

.table-layout {
    width: 1100px;
    margin-left: auto;
    margin-right: auto;
}

.table-clearfix:after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.table-legend {
    height: 30px;
    width: 30px;
    float: left;
    margin-right: 10px;
    clear: both;
}
